import { createSlice } from "@reduxjs/toolkit";
import {
  addEvent,
  addEventMultiple,
  getAllEvents,
  getAllEventsByTagId,
  editEvent,
  delEvent,
  editMultipleEvents,
  checkEventsConflict,
  makeBulkEventsPublic
} from "../actions/events";
import { logoutUser } from "../actions/account";

const initialState = {
  offlineStatus: false,
  allGoalStatus: false,
  persistedLocalEvents: [],
  persistedLocalGoals: [],
  showAllGoalStatus: false,
  persistedCalenderView: "dayGridMonth",
  persistedProfiles: [],
  persistedLocation: [],
  persistedProduct: [],
  persistedCategories: [],
  persistedGoalType: [],
  isLoadingCheckEventsConflict: false,
  conflictObj: {},
  calDate:null
};

const eventUISlice = createSlice({
  name: "eventsUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeOfflineStatus(state, action) {
      state.offlineStatus = action.payload;
    },
    storeCalendarDate(state, action) {
      state.calDate = action.payload;
    },
    storeAllGoalsStatus(state, action) {
      state.allGoalStatus = action.payload;
    },
    showAllGoalStatus(state, action) {
      state.showAllGoalStatus = action.payload;
    },
    storeLocalEvents(state, action) {
      state.persistedLocalEvents = action.payload;
    },
    storeLocalGoals(state, action) {
      state.persistedLocalGoals = action.payload;
    },
    storeCalenderView(state, action) {
      state.persistedCalenderView = action.payload;
    },
    storeSelectedProfiles(state, action) {
      state.persistedProfiles = action.payload;
    },
    storeSelectedLocations(state, action) {
      state.persistedLocation = action?.payload;
    },
    storeSelectedProducts(state, action) {
      state.persistedProduct = action?.payload;
    },
    storeSelectedCategories(state, action) {
      state.persistedCategories = action?.payload;
    },
    storeSelectedGoalType(state, action) {
      state.persistedGoalType = action?.payload;
    }
  },
  extraReducers: {},
});

export const getOfflineStatus = (state) => state.eventsUI.offlineStatus;
export const getPersistedLocalEvents = (state) =>
  state.eventsUI.persistedLocalEvents;
export const getPersistedLocalGoals = (state) =>
  state.eventsUI.persistedLocalGoals;
export const getAllGoalStatus = (state) => state.eventsUI.allGoalStatus;
export const getShowAllGoalStatus = (state) => state.eventsUI.showAllGoalStatus;
export const getCalenderView = (state) => state.eventsUI.persistedCalenderView
export const getPersistedProfileForFilters = (state) => state.eventsUI.persistedProfiles;
export const getPersistedLocationForFilters = (state) => state.eventsUI.persistedLocation;
export const getPersistedProductForFilters = (state) => state.eventsUI.persistedProduct;
export const getPersistedCategoriesFroFilters = (state) => state.eventsUI.persistedCategories;
export const getPersistedGoalTypeForFilter = (state) => state.eventsUI.persistedGoalType;
export const getCalendarDate = (state) => state.eventsUI.calDate;

export const {
  storeOfflineStatus,
  storeLocalEvents,
  storeLocalGoals,
  storeAllGoalsStatus,
  showAllGoalStatus,
  storeCalenderView,
  storeSelectedProfiles,
  storeSelectedLocations,
  storeSelectedProducts,
  storeSelectedCategories,
  storeSelectedGoalType,
  storeCalendarDate
} = eventUISlice.actions;

export default eventUISlice;
