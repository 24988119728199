import { createSlice } from "@reduxjs/toolkit";

import {
    addVendorInvoices, getVendorInvoiceById, editVendorInvoiceById, publishVendorInvoiceById, payVendorInvoiceById, cancelVendorInvoiceById, addPaidVendorInvoice, getVendorInvoiceForInstitution, mergeCoverVendorInvoice
} from "../actions/vendorInvoices";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingAddVendorInvoices: false,
    isLoadingGetVendorInvoiceById: false,
    invoiceDetails: {},
    isLoadingEditVendorInvoiceByid: false,
    isLoadingPublishVendorInvoiceById: false,
    isLoadingPayVendorInvoiceById: false,
    isLoadingCancelVendorInvoiceById: false,
    isLoadingGetAllVendorInvoice: false,
    vendorInvoicesList: [],
};

const vendorInvoicesSlice = createSlice({
    name: "vendorInvoices",
    initialState,
    reducers: {},
    extraReducers: {
        //addVendorInvoices
        [addVendorInvoices.pending]: (state, action) => {
            state.isLoadingAddVendorInvoices = true;
            state.error = false;
        },
        [addVendorInvoices.fulfilled]: (state, action) => {
            state.isLoadingAddVendorInvoices = false;
            state.invoiceDetails = action?.payload?.data;
            state.error = false;
        },
        [addVendorInvoices.rejected]: (state, action) => {
            state.isLoadingAddVendorInvoices = false;
            state.error = true;
        },
        //getVendorInvoiceById
        [getVendorInvoiceById.pending]: (state, action) => {
            state.isLoadingGetVendorInvoiceById = true;
            state.error = false;
        },
        [getVendorInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingGetVendorInvoiceById = false;
            state.invoiceDetails = action?.payload?.data?.vendor_invoice;
            state.error = false;
        },
        [getVendorInvoiceById.rejected]: (state, action) => {
            state.isLoadingGetVendorInvoiceById = false;
            state.error = true;
        },//getVendorInvoiceForInstitution
        [getVendorInvoiceForInstitution.pending]: (state, action) => {
            state.isLoadingGetAllVendorInvoice = true;
            state.error = false;
        },
        [getVendorInvoiceForInstitution.fulfilled]: (state, action) => {
            state.isLoadingGetAllVendorInvoice = false;
            state.vendorInvoicesList = action?.payload?.data;
            state.error = false;
        },
        [getVendorInvoiceForInstitution.rejected]: (state, action) => {
            state.isLoadingGetAllVendorInvoice = false;
            state.error = true;
        },//getVendorInvoiceById
        [addPaidVendorInvoice.pending]: (state, action) => {
            state.isLoadingDirectPayInvoice = true;
            state.error = false;
        },
        [addPaidVendorInvoice.fulfilled]: (state, action) => {
            state.isLoadingDirectPayVendorInvoice = false;
            state.postReturnData = action?.payload?.data;
            state.error = false;
        },
        [addPaidVendorInvoice.rejected]: (state, action) => {
            state.isLoadingDirectPayVendorInvoice = false;
            state.error = true;
        },
        [editVendorInvoiceById.pending]: (state, action) => {
            state.isLoadingEditVendorInvoiceByid = true;
            state.error = false;
        },
        [editVendorInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingEditVendorInvoiceByid = false;
            state.error = false;
        },
        [editVendorInvoiceById.rejected]: (state, action) => {
            state.isLoadingEditVendorInvoiceByid = false;
            state.error = true;
        },
        [publishVendorInvoiceById.pending]: (state, action) => {
            state.isLoadingPublishVendorInvoiceById = true;
            state.error = false;
        },
        [publishVendorInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPublishVendorInvoiceById = false;
            state.error = false;
        },
        [publishVendorInvoiceById.rejected]: (state, action) => {
            state.isLoadingPublishVendorInvoiceById = false;
            state.error = true;
        },
        [payVendorInvoiceById.pending]: (state, action) => {
            state.isLoadingPayVendorInvoiceById = true;
            state.error = false;
        },
        [payVendorInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPayVendorInvoiceById = false;
            state.error = false;
        },
        [payVendorInvoiceById.rejected]: (state, action) => {
            state.isLoadingPayVendorInvoiceById = false;
            state.error = true;
        },
        [cancelVendorInvoiceById.pending]: (state, action) => {
            state.isLoadingCancelVendorInvoiceById = true;
            state.error = false;
        },
        [cancelVendorInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingCancelVendorInvoiceById = false;
            state.error = false;
        },
        [cancelVendorInvoiceById.rejected]: (state, action) => {
            state.isLoadingCancelVendorInvoiceById = false;
            state.error = true;
        },
        [mergeCoverVendorInvoice.pending]: (state, action) => {
            state.isLoadingMergeCoverVendorInvoice = true;
            state.error = false;
        },
        [mergeCoverVendorInvoice.fulfilled]: (state, action) => {
            state.isLoadingMergeCoverVendorInvoice = false;
            state.error = false;
        },
        [mergeCoverVendorInvoice.rejected]: (state, action) => {
            state.isLoadingMergeCoverVendorInvoice = false;
            state.error = true;
        },

        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.vendorInvoices.error;
export const getIsLoadingAddVendorInvoices = (state) => state.vendorInvoices.isLoadingAddVendorInvoices
export const getIsLoadingGetVendorInvoiceById = (state) => state.vendorInvoices.isLoadingGetVendorInvoiceById
export const getVendorInvoiceDetails = (state) => state.vendorInvoices.invoiceDetails
export const getIsLoadingEditVendorInvoiceByid = (state) =>
    state.vendorInvoices.isLoadingEditVendorInvoiceByid;
export const getIsLoadingPublishVendorInvoiceById = (state) =>
    state.vendorInvoices.isLoadingPublishVendorInvoiceById;
export const getIsLoadingPayVendorInvoiceById = (state) =>
    state.vendorInvoices.isLoadingPayVendorInvoiceById;
export const getIsLoadingCancelVendorInvoiceById = (state) =>
    state.vendorInvoices.isLoadingCancelVendorInvoiceById;
export const getIsLoadingAllVendorInvoice = (state) =>
    state.vendorInvoices.isLoadingGetAllVendorInvoice;
export const getVendorInvoicesList = (state) =>
    state.vendorInvoices.vendorInvoicesList;
export const getIsLoadingMergeCoverVendorInvoice = (state) =>
    state.vendorInvoices.isLoadingMergeCoverVendorInvoice;




export default vendorInvoicesSlice;

