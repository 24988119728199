import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from "underscore";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer, Typography } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import { useSelector, useDispatch } from "react-redux";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import LogoMain from "../../../components/LogoMain";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
//
import { ICONS, getCustomIcon } from "./NavConfig";
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from "./CollapseButton";

//reducers

import { getJwt, getInstitutionId, getProfile } from "src/redux/slices/account";
import { showAllGoalStatus, storeAllGoalsStatus } from "src/redux/slices/eventsUI";
import {
  getUserNavigationArray,
} from "../../../redux/slices/navigation";
import { getInstitutionDetails } from "src/redux/slices/institution";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const jwt = useSelector(getJwt);
  const institutionId = useSelector(getInstitutionId);
  const profile = useSelector(getProfile);

  //navigation
  const userNavigations = useSelector(getUserNavigationArray);
  const institutionDetails = useSelector(getInstitutionDetails);

  let navConfigGrouped = _.groupBy(
    userNavigations?.slice()?.sort((a, b) => a.section_order - b.section_order),
    "section_name"
  );

  let meEventsExists = false
  let EventsExists = false
  let mainIndex = 0
  let subIndexIndex = 0

  let navConfigNew = Object.keys(navConfigGrouped)?.map((key, mainindex) => {
    return {
      subheader: key,
      items: navConfigGrouped[key]
        ?.slice()
        ?.sort((a, b) => a?.order - b?.order)
        ?.map((item, index) => {
          if (item?.link === "events") {
            EventsExists = true

          } else if (item?.link === "me/events") {
            meEventsExists = true
            mainIndex = mainindex
            subIndexIndex = index

          }
          return {
            icon: ICONS?.[item?.icon] ? ICONS?.[item?.icon] : getCustomIcon(item?.icon),
            path: "/" + item?.link,
            title: item?.title,
          };
        }),
    };
  });

  if (meEventsExists && EventsExists) {
    delete navConfigNew?.[mainIndex]?.items?.[subIndexIndex]
  }

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const filteredEvents = userNavigations?.filter(item => item?.link === "events")
    const filteredMeEvents = userNavigations?.filter(item => item?.link === "me/events")
    if (filteredEvents && filteredEvents?.length > 0) {
      if (filteredMeEvents && filteredMeEvents?.length > 0) {
        dispatch(showAllGoalStatus(true))
      }
      else {
        dispatch(showAllGoalStatus(false))
      }
    }
    else {
      dispatch(showAllGoalStatus(false))
    }
  }, [userNavigations]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isCollapse ? <LogoMain /> : <Logo />}

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
        {!isCollapse && <Stack alignItems="center">
          <Typography variant="h6">{institutionDetails?.name}</Typography>
        </Stack>}

        <NavbarAccount profile={profile} isCollapse={isCollapse} />
      </Stack>

      <NavSectionVertical navConfig={navConfigNew} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && <NavbarDocs />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              // bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
