import { createSlice } from "@reduxjs/toolkit";
import {
  addProfileGroup,
  getProfileGroups,
  editProfileGroup,
  delProfileGroup,
  addGroupCsv
} from "../actions/groups";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddProfileGroup: false,
  isLoadingGetProfileGroups: false,
  isLoadingEditProfileGroups: false,
  isLoadingDelProfileGroup: false,
  profileGroups: [],
  isLoadingAddGroupCsv: false,
  returnNewGroupData: {},
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
  },
  extraReducers: {
    [addProfileGroup.pending]: (state, action) => {
      state.isLoadingAddProfileGroup = true;
      state.error = false;
    },
    [addProfileGroup.fulfilled]: (state, action) => {
      state.isLoadingAddProfileGroup = false;
      state.error = false;
    },
    [addProfileGroup.rejected]: (state, action) => {
      state.isLoadingAddProfileGroup = false;
      state.error = true;
    },//addGroupCsv
    [addGroupCsv.pending]: (state, action) => {
      state.isLoadingAddGroupCsv = true;
      state.error = false;
    },
    [addGroupCsv.fulfilled]: (state, action) => {
      state.isLoadingAddGroupCsv = false;
      state.returnNewGroupData = action?.payload?.data
      state.error = false;
    },
    [addGroupCsv.rejected]: (state, action) => {
      state.isLoadingAddGroupCsv = false;
      state.error = true;
    },
    [getProfileGroups.pending]: (state, action) => {
      state.isLoadingGetProfileGroups = true;
      state.error = false;
    },
    [getProfileGroups.fulfilled]: (state, action) => {
      state.isLoadingGetProfileGroups = false;
      state.profileGroups = action?.payload?.data?.groups;
      state.error = false;
    },
    [getProfileGroups.rejected]: (state, action) => {
      state.isLoadingGetProfileGroups = false;
      state.error = true;
    },
    [editProfileGroup.pending]: (state, action) => {
      state.isLoadingEditProfileGroups = true;
      state.error = false;
    },
    [editProfileGroup.fulfilled]: (state, action) => {
      state.isLoadingEditProfileGroups = false;
      state.error = false;
    },
    [editProfileGroup.rejected]: (state, action) => {
      state.isLoadingEditProfileGroups = false;
      state.error = true;
    },
    [delProfileGroup.pending]: (state, action) => {
      state.isLoadingDelProfileGroup = true;
      state.error = false;
    },
    [delProfileGroup.fulfilled]: (state, action) => {
      state.isLoadingDelProfileGroup = false;
      state.error = false;
    },
    [delProfileGroup.rejected]: (state, action) => {
      state.isLoadingDelProfileGroup = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});
//delGoalById
export const getError = (state) => state.groups.error;
export const getIsLoadingAddProfileGroup = (state) =>
  state.groups.isLoadingAddProfileGroup;
export const getIsLoadingGetProfileGroups = (state) =>
  state.groups.isLoadingGetProfileGroups;
export const getIsLoadingEditProfileGroups = (state) =>
  state.groups.isLoadingEditProfileGroups;
export const getIsLoadingDelProfileGroup = (state) =>
  state.groups.isLoadingDelProfileGroup;
export const getProfileGroupsList = (state) => state.groups.profileGroups;
export const getIsLoadingAddGroupCsv = (state) => state.groups.isLoadingAddGroupCsv;
export const getReturnNewGroupData = (state) => state.groups.returnNewGroupData;

export default groupSlice;
