import { createSlice } from "@reduxjs/toolkit";
import {
  getProfileByIds,
  getProfileById,
  delProfileById,
  getProfileByInstitution,
  getProfileByInstitutionTemp,
  getProfileSchema,
  addProfile,
  editProfileById,
  getProfileByModuleId,
  getProfilePermissions,
} from "../actions/profile";
import { editProfileGroup } from "../actions/groups";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingProfiles: false,
  isLoadingProfilesByInstitution: false,
  isLoadingProfilesByInstitutionTemp: false,
  isLoadingProfileSchema: false,
  isLoadingAddProfiles: false,
  isLoadingEditProfiles: false,
  isLoadingGetProfileByModId: false,
  profileDetails: {},
  groupsForProfile: [],
  profileSchema: [],
  profilesForInstitution: [],
  profileByInstitutionById: {},
  isLoadingDel: false,
  profilesForModule: [],
  singlePorfileDetails: {},
  profileClients: [],
  profileClientSchema: [],
  profileGoals: [],
  profileGoalsSchema: [],
  profileVendors: [],
  profileVendorsSchema: [],
  profileVendorTag: {},
  profileClientTag: {},
  isLoadingGetProfilePermissions: false,
  profilePermissionList: [],
  profileEvents: [],
  profileTag: {}
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: {
    [getProfileByIds.pending]: (state, action) => {
      state.isLoadingProfiles = true;
      state.error = false;
    },
    [getProfileByIds.fulfilled]: (state, action) => {
      state.isLoadingProfiles = false;
      state.profileDetails = action?.payload?.profiles;
      state.profileSchema = action?.payload?.profiles_schema;
      state.error = false;
    },
    [getProfileByIds.rejected]: (state, action) => {
      state.isLoadingProfiles = false;
      state.error = true;
    },
    [getProfileById.pending]: (state, action) => {
      state.isLoadingProfiles = true;
      state.error = false;
    },
    [getProfileById.fulfilled]: (state, action) => {
      state.isLoadingProfiles = false;
      state.singlePorfileDetails = action?.payload?.profiles;
      state.groupsForProfile = action?.payload?.profileGroups;
      state.profileSchema = action?.payload?.profiles_schema;
      state.profileClients = action?.payload?.clients;
      state.profileEvents = action?.payload?.events;
      state.profileClientSchema = action?.payload?.clientSchema;
      state.profileVendors = action?.payload?.vendors;
      state.profileVendorsSchema = action?.payload?.vendorsSchema;
      state.profileGoals = action?.payload?.goals;
      state.profileGoalsSchema = action?.payload?.goals_schema;
      state.error = false;
      state.profileVendorTag = action?.payload?.vendors_tags;
      state.profileClientTag = action?.payload?.clients_tags;
      state.profileTag = action?.payload?.profile_tags;
    },
    [getProfileById.rejected]: (state, action) => {
      state.isLoadingProfiles = false;
      state.error = true;
    },
    [getProfileByModuleId.pending]: (state, action) => {
      state.isLoadingGetProfileByModId = true;
      state.error = false;
    },
    [getProfileByModuleId.fulfilled]: (state, action) => {
      state.isLoadingGetProfileByModId = false;
      state.profilesForModule = action?.payload?.profiles;
      state.error = false;
    },
    [getProfileByModuleId.rejected]: (state, action) => {
      state.isLoadingGetProfileByModId = false;
      state.error = true;
    },
    [addProfile.pending]: (state, action) => {
      state.isLoadingAddProfiles = true;
      state.error = false;
    },
    [addProfile.fulfilled]: (state, action) => {
      state.isLoadingAddProfiles = false;
      state.singlePorfileDetails = action?.payload?.profile;
      state.profilesForInstitution = (state.profilesForInstitution && Array.isArray(state.profilesForInstitution) ? state.profilesForInstitution : [])?.concat([action?.payload?.profile])
      state.profileDetails = (state.profileDetails && Array.isArray(state.profileDetails) ? state.profileDetails : [])?.concat([action?.payload?.profile])
      state.error = false;
    },
    [addProfile.rejected]: (state, action) => {
      state.isLoadingAddProfiles = false;
      state.error = true;
    },
    [editProfileById.pending]: (state, action) => {
      state.isLoadingEditProfiles = true;
      state.error = false;
    },
    [editProfileById.fulfilled]: (state, action) => {
      state.isLoadingEditProfiles = false;
      state.singlePorfileDetails = action?.payload?.profile;
      state.error = false;
    },
    [editProfileById.rejected]: (state, action) => {
      state.isLoadingEditProfiles = false;
      state.error = true;
    },
    [getProfileSchema.pending]: (state, action) => {
      state.isLoadingProfileSchema = true;
      state.error = false;
    },
    [getProfileSchema.fulfilled]: (state, action) => {
      state.isLoadingProfileSchema = false;
      state.profileSchema = action?.payload;
      state.error = false;
    },
    [getProfileSchema.rejected]: (state, action) => {
      state.isLoadingProfileSchema = false;
      state.error = true;
    },
    [getProfileByInstitution.pending]: (state, action) => {
      state.isLoadingProfilesByInstitution = true;
      state.error = false;
    },
    [getProfileByInstitution.fulfilled]: (state, action) => {
      state.isLoadingProfilesByInstitution = false;
      state.profilesForInstitution = action?.payload?.profiles;
      state.profileByInstitutionById = action?.payload?.byId;
      state.profileSchema = action?.payload?.profiles_schema;
      state.error = false;
    },
    [getProfileByInstitution.rejected]: (state, action) => {
      state.isLoadingProfilesByInstitution = false;
      state.error = true;
    },//getProfileByInstitutionTemp
    [getProfileByInstitutionTemp.pending]: (state, action) => {
      state.isLoadingProfilesByInstitutionTemp = true;
      state.error = false;
    },
    [getProfileByInstitutionTemp.fulfilled]: (state, action) => {
      state.isLoadingProfilesByInstitutionTemp = false;
      state.profilesForInstitution = Array.isArray(action?.payload?.profiles) ? action?.payload?.profiles : [action?.payload?.profiles];
      state.profileByInstitutionById = action?.payload?.byId;
      state.profileSchema = action?.payload?.profiles_schema;
      state.error = false;
    },
    [getProfileByInstitutionTemp.rejected]: (state, action) => {
      state.profilesForInstitution = [];
      state.profileByInstitutionById = {};
      state.profileSchema = [];
      state.isLoadingProfilesByInstitutionTemp = false;
      state.error = true;
    },//
    [delProfileById.pending]: (state, action) => {
      state.isLoadingDel = true;
      state.error = false;
    },
    [delProfileById.fulfilled]: (state, action) => {
      state.isLoadingDel = false;
      state.error = false;
    },
    [delProfileById.rejected]: (state, action) => {
      state.isLoadingDel = false;
      state.error = true;
    },//getProfilePermissions
    [getProfilePermissions.pending]: (state, action) => {
      state.isLoadingGetProfilePermissions = true;
      state.error = false;
    },
    [getProfilePermissions.fulfilled]: (state, action) => {
      state.isLoadingGetProfilePermissions = false;
      state.profilePermissionList = action?.payload?.data?.profilePermissionObjects
      state.error = false;
    },
    [getProfilePermissions.rejected]: (state, action) => {
      state.isLoadingGetProfilePermissions = false;
      state.error = true;
    },
    [editProfileGroup.fulfilled]: (state, action) => {
      state.groupsForProfile = action?.payload?.profileGroups;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.profile.error;
export const getIsLoadingProfiles = (state) => state.profile.isLoadingProfiles;
export const getIsLoadingProfilesByInstitution = (state) =>
  state.profile.isLoadingProfilesByInstitution;
export const getIsLoadingDel = (state) => state.profile.isLoadingDel;
export const getIsLoadingProfileSchema = (state) =>
  state.profile.isLoadingProfileSchema;
export const getIsLoadingAddProfiles = (state) =>
  state.profile.isLoadingAddProfiles;
export const getIsLoadingEditProfiles = (state) =>
  state.profile.isLoadingEditProfiles;
export const getIsLoadingGetProfileByModId = (state) =>
  state.profile.isLoadingGetProfileByModId;
export const getProfileDetails = (state) => state.profile.profileDetails;
export const getProfileGroups = (state) => state.profile.groupsForProfile;
export const getProfilesForModule = (state) => state.profile.profilesForModule;
export const getProfileSchemaArray = (state) => state.profile.profileSchema;
export const getProfilesForInstitution = (state) =>
  state.profile.profilesForInstitution;
export const getProfilesForInstitutionById = (state) => {
  return state.profile.profileByInstitutionById;
};
export const getIsLoadingProfilesByInstitutionTemp = (state) => {
  return state.profile.isLoadingProfilesByInstitutionTemp;
};
export const getSinglePorfileDetails = (state) =>
  state.profile.singlePorfileDetails;
export const getProfileClients = (state) => state.profile.profileClients;
export const getProfileVendors = (state) => state.profile.profileVendors;
export const getProfileVendorsSchema = (state) => state.profile.profileVendorsSchema;
export const getProfileClientSchema = (state) =>
  state.profile.profileClientSchema;
export const getProfileGoals = (state) => state.profile.profileGoals;
export const getProfileGoalsSchema = (state) =>
  state.profile.profileGoalsSchema;

export const getProfileVendorTag = (state) => state.profile.profileVendorTag;
export const getProfileClientTag = (state) => state.profile.profileClientTag;
export const getIsLoadingProfilePermissions = (state) => state.profile.isLoadingGetProfilePermissions;
export const getProfilePermissionList = (state) => state.profile.profilePermissionList;
export const getProfileEvents = (state) => state.profile.profileEvents;

export const getProfileTag = (state) => state.profile.profileTag;

export default profileSlice;
