import { createSlice } from "@reduxjs/toolkit";
import {
    getAllVendorsItemsById,
    addVendorItem,
    editVendorItemsById,
    delVendorItem,
    getAllVendorsItemsForInstitution
} from "../actions/vendorItems";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingGetAllVendorItems: false,
    vendorItemsList: [],
    isLoadingAddVendorItem: false,
    isLoadingEditVendorItem: false,
    isLoadingDeleteVendorItem: false,
    isLoadingVendorItemsForInstitution: false,
    vendorItemsInstitutionList: []
};

const vendorItemSlice = createSlice({
    name: "vendorItems",
    initialState,
    reducers: {},
    extraReducers: {
        //getAllVendorsItemsForInstitution
        [getAllVendorsItemsForInstitution.pending]: (state, action) => {
            state.isLoadingVendorItemsForInstitution = true;
            state.error = false;
        },
        [getAllVendorsItemsForInstitution.fulfilled]: (state, action) => {
            state.isLoadingVendorItemsForInstitution = false;
            state.vendorItemsInstitutionList = action?.payload?.data?.vendorItems;
            state.error = false;
        },
        [getAllVendorsItemsForInstitution.rejected]: (state, action) => {
            state.isLoadingVendorItemsForInstitution = true;
            state.error = false;
        },
        [getAllVendorsItemsById.pending]: (state, action) => {
            state.isLoadingGetAllVendorItems = true;
            state.error = false;
        },
        [getAllVendorsItemsById.fulfilled]: (state, action) => {
            state.isLoadingGetAllVendorItems = false;
            state.vendorItemsList = action?.payload?.data?.vendorItems;
            state.error = false;
        },
        [getAllVendorsItemsById.rejected]: (state, action) => {
            state.isLoadingGetAllVendorItems = false;
            state.error = true;
        },//addVendorItem
        [addVendorItem.pending]: (state, action) => {
            state.isLoadingAddVendorItem = true;
            state.error = false;
        },
        [addVendorItem.fulfilled]: (state, action) => {
            state.isLoadingAddVendorItem = false;
            state.vendorItemsList = action?.payload?.data?.vendorItems;
            state.error = false;
        },
        [addVendorItem.rejected]: (state, action) => {
            state.isLoadingAddVendorItem = false;
            state.error = true;
        },//editVendorItemsById
        [editVendorItemsById.pending]: (state, action) => {
            state.isLoadingEditVendorItem = true;
            state.error = false;
        },
        [editVendorItemsById.fulfilled]: (state, action) => {
            state.isLoadingEditVendorItem = false;
            state.error = false;
        },
        [editVendorItemsById.rejected]: (state, action) => {
            state.isLoadingEditVendorItem = false;
            state.error = true;
        },//delVendorItem
        [delVendorItem.pending]: (state, action) => {
            state.isLoadingDeleteVendorItem = true;
            state.error = false;
        },
        [delVendorItem.fulfilled]: (state, action) => {
            state.isLoadingDeleteVendorItem = false;
            state.error = false;
        },
        [delVendorItem.rejected]: (state, action) => {
            state.isLoadingDeleteVendorItem = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.vendorItems.error;
export const getIsLoadingAllVendorItems = (state) => state.vendorItems.isLoadingGetAllVendorItems;
export const getVendorItemsList = (state) => state.vendorItems.vendorItemsList;
export const getIsLoadingAddVendorItem = (state) => state.vendorItems.isLoadingAddVendorItem;
export const getIsLoadingEditVendorItem = (state) => state.vendorItems.isLoadingEditVendorItem;
export const getIsLoadingDeleteVendorItem = (state) => state.vendorItems.isLoadingDeleteVendorItem;
export const getIsLoadingVendorItemsForInstitution = (state) => state.vendorItems.isLoadingVendorItemsForInstitution;
export const getVendorItemsForInstitutionList = (state) => state.vendorItems.vendorItemsInstitutionList;


export default vendorItemSlice;

