import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isPaymentProcessing: false
};

const invoiceUISlice = createSlice({
    name: "invoiceUI",
    initialState,
    reducers: {
        "account/logoutUser": (state) => initialState,
        clearPaymentProcessing(state) {
            state.isPaymentProcessing = false;
        },
        setPaymentProcessing(state) {
            state.isPaymentProcessing = true;
        },
    },
    extraReducers: {},
});

export const getIsPaymentProcessing = (state) =>
    state.invoiceUI.isPaymentProcessing;

export const {
    clearPaymentProcessing,
    setPaymentProcessing
} = invoiceUISlice.actions;


export default invoiceUISlice;
