import { createSlice } from "@reduxjs/toolkit";
import { editWorkorderItemById, addWorkorderItem, delWorkorderItem } from "../actions/workorderItems";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingEditWorkorderItem: false,
    isLoadingAddWorkorderItem: false,
    isLoadingDeleteWorkorderItem: false,
};

const workorderItemSlice = createSlice({
    name: "workorderItems",
    initialState,
    reducers: {},
    extraReducers: {
        //editWorkorderItemById
        [editWorkorderItemById.pending]: (state, action) => {
            state.isLoadingEditWorkorderItem = true;
            state.error = false;
        },
        [editWorkorderItemById.fulfilled]: (state, action) => {
            state.isLoadingEditWorkorderItem = false;
            state.error = false;
        },
        [editWorkorderItemById.rejected]: (state, action) => {
            state.isLoadingEditWorkorderItem = false;
            state.error = true;
        },//addWorkorderItem
        [addWorkorderItem.pending]: (state, action) => {
            state.isLoadingAddWorkorderItem = true;
            state.error = false;
        },
        [addWorkorderItem.fulfilled]: (state, action) => {
            state.isLoadingAddWorkorderItem = false;
            state.error = false;
        },
        [addWorkorderItem.rejected]: (state, action) => {
            state.isLoadingAddWorkorderItem = false;
            state.error = true;
        },//delWorkorderItem
        [delWorkorderItem.pending]: (state, action) => {
            state.isLoadingDeleteWorkorderItem = true;
            state.error = false;
        },
        [delWorkorderItem.fulfilled]: (state, action) => {
            state.isLoadingDeleteWorkorderItem = false;
            state.error = false;
        },
        [delWorkorderItem.rejected]: (state, action) => {
            state.isLoadingDeleteWorkorderItem = false;
            state.error = true;
        },

        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.workorderItems.error;
export const getIsLoadingEditWorkorderItem = (state) => state.workorderItems.isLoadingEditWorkorderItem;
export const getIsLoadingAddWorkorderItem = (state) => state.workorderItems.isLoadingAddWorkorderItem;
export const getIsLoadingDeleteWorkorderItem = (state) => state.workorderItems.isLoadingDeleteWorkorderItem;



export default workorderItemSlice;

