import { createSlice } from "@reduxjs/toolkit";
import {
    addInvoice,
    getInvoiceById,
    editInvoiceById,
    publishInvoiceById,
    payInvoiceById,
    getInvoiceByGoalId,
    cancelInvoiceById,
    addPaidInvoice,
    getAllInvoicesforInstitution,
    addSubInvoices,
    publishSubInvoiceById,
    paySubInvoiceById,
    cancelSubInvoiceById,
    refundInvoice,
    sendEmailNotification,
    addPaidSubInvoice
} from "../actions/invoice";
import { logoutUser } from "../actions/account";


const initialState = {
    error: false,
    isLoadingAddInvoice: false,
    isLoadingGetInvoiceDetails: false,
    invoiceDetails: {},
    postReturnData: {},
    isLoadingEditInvoiceByid: false,
    isLoadingGetInvoiceByGoal: false,
    invoicesForGoal: [],
    isLoadingPublishInvoiceById: false,
    isLoadingPayInvoiceById: false,
    isLoadingCancelInvoiceById: false,
    isLoadingDirectPayInvoice: false,
    isLoadingDirectPaySubInvoice:false,
    isLoadingGetInvoicesForInstitution: false,
    invoicesForInstitution: [],
    isLoadingAddSubInvoices: false,
    isLoadingPublishSubInvoiceById: false,
    isLoadingPaySubInvoice: false,
    isLoadingCancelsubInvoice: false,
    isLoadingRefundInvoice: false,
    isLoadingSendEmailNotification: false,
};

const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {},
    extraReducers: {

        [addInvoice.pending]: (state, action) => {
            state.isLoadingAddInvoice = true;
            state.error = false;
        },
        [addInvoice.fulfilled]: (state, action) => {
            state.postReturnData = action?.payload?.data;
            state.isLoadingAddInvoice = false;
            state.error = false;
        },
        [addInvoice.rejected]: (state, action) => {
            state.isLoadingAddInvoice = false;
            state.error = true;
        },//addSubInvoices
        [addSubInvoices.pending]: (state, action) => {
            state.isLoadingAddSubInvoices = true;
            state.error = false;
        },
        [addSubInvoices.fulfilled]: (state, action) => {
            state.invoiceDetails = action?.payload?.data;
            state.isLoadingAddSubInvoices = false;
            state.error = false;
        },
        [addSubInvoices.rejected]: (state, action) => {
            state.isLoadingAddSubInvoices = false;
            state.error = true;
        },
        [addPaidInvoice.pending]: (state, action) => {
            state.isLoadingDirectPayInvoice = true;
            state.error = false;
        },
        [addPaidInvoice.fulfilled]: (state, action) => {
            state.isLoadingDirectPayInvoice = false;
            state.postReturnData = action?.payload?.data;
            state.error = false;
        },
        [addPaidInvoice.rejected]: (state, action) => {
            state.isLoadingDirectPayInvoice = false;
            state.error = true;
        },
        [addPaidSubInvoice.pending]: (state, action) => {
            state.isLoadingDirectPaySubInvoice = true;
            state.error = false;
        },
        [addPaidSubInvoice.fulfilled]: (state, action) => {
            state.isLoadingDirectPaySubInvoice = false;
            state.invoiceDetails = action?.payload?.data;
            state.error = false;
        },
        [addPaidSubInvoice.rejected]: (state, action) => {
            state.isLoadingDirectPaySubInvoice = false;
            state.error = true;
        },
        [editInvoiceById.pending]: (state, action) => {
            state.isLoadingEditInvoiceByid = true;
            state.error = false;
        },
        [editInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingEditInvoiceByid = false;
            state.error = false;
        },
        [editInvoiceById.rejected]: (state, action) => {
            state.isLoadingEditInvoiceByid = false;
            state.error = true;
        },
        [getInvoiceById.pending]: (state, action) => {
            state.isLoadingGetInvoiceDetails = true;
            state.error = false;
        },
        [getInvoiceById.fulfilled]: (state, action) => {
            state.invoiceDetails = action?.payload?.data;
            state.isLoadingGetInvoiceDetails = false;
            state.error = false;
        },
        [getInvoiceById.rejected]: (state, action) => {
            state.isLoadingGetInvoiceDetails = false;
            state.error = true;
        },
        [getInvoiceByGoalId.pending]: (state, action) => {
            state.isLoadingGetInvoiceByGoal = true;
            state.error = false;
        },
        [getInvoiceByGoalId.fulfilled]: (state, action) => {
            state.invoicesForGoal = action?.payload?.data;
            state.isLoadingGetInvoiceByGoal = false;
            state.error = false;
        },
        [getInvoiceByGoalId.rejected]: (state, action) => {
            state.isLoadingGetInvoiceByGoal = false;
            state.error = true;
        },//getAllInvoicesforInstitution
        [getAllInvoicesforInstitution.pending]: (state, action) => {
            state.isLoadingGetInvoicesForInstitution = true;
            state.error = false;
        },
        [getAllInvoicesforInstitution.fulfilled]: (state, action) => {
            state.invoicesForInstitution = action?.payload?.data;
            state.isLoadingGetInvoicesForInstitution = false;
            state.error = false;
        },
        [getAllInvoicesforInstitution.rejected]: (state, action) => {
            state.isLoadingGetInvoicesForInstitution = false;
            state.error = true;
        },
        [publishInvoiceById.pending]: (state, action) => {
            state.isLoadingPublishInvoiceById = true;
            state.error = false;
        },
        [publishInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPublishInvoiceById = false;
            state.error = false;
        },
        [publishInvoiceById.rejected]: (state, action) => {
            state.isLoadingPublishInvoiceById = false;
            state.error = true;
        },//publishSubInvoiceById
        [publishSubInvoiceById.pending]: (state, action) => {
            state.isLoadingPublishSubInvoiceById = true;
            state.error = false;
        },
        [publishSubInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPublishSubInvoiceById = false;
            state.invoiceDetails = action?.payload?.data;
            state.error = false;
        },
        [publishSubInvoiceById.rejected]: (state, action) => {
            state.isLoadingPublishSubInvoiceById = false;
            state.error = true;
        },
        [payInvoiceById.pending]: (state, action) => {
            state.isLoadingPayInvoiceById = true;
            state.error = false;
        },
        [payInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPayInvoiceById = false;
            state.error = false;
        },
        [payInvoiceById.rejected]: (state, action) => {
            state.isLoadingPayInvoiceById = false;
            state.error = true;
        },//paySubInvoiceById
        [paySubInvoiceById.pending]: (state, action) => {
            state.isLoadingPaySubInvoice = true;
            state.error = false;
        },
        [paySubInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingPaySubInvoice = false;
            state.invoiceDetails = action?.payload?.data;
            state.error = false;
        },
        [paySubInvoiceById.rejected]: (state, action) => {
            state.isLoadingPaySubInvoice = false;
            state.error = true;
        },
        [cancelInvoiceById.pending]: (state, action) => {
            state.isLoadingCancelInvoiceById = true;
            state.error = false;
        },
        [cancelInvoiceById.fulfilled]: (state, action) => {
            state.isLoadingCancelInvoiceById = false;
            state.error = false;
        },
        [cancelInvoiceById.rejected]: (state, action) => {
            state.isLoadingCancelInvoiceById = false;
            state.error = true;
        },//cancelSubInvoiceById
        [cancelSubInvoiceById.pending]: (state, action) => {
            state.isLoadingCancelsubInvoice = true;
            state.error = false;
        },
        [cancelSubInvoiceById.fulfilled]: (state, action) => {
            state.invoiceDetails = action?.payload?.data;
            state.isLoadingCancelsubInvoice = false;
            state.error = false;
        },
        [cancelSubInvoiceById.rejected]: (state, action) => {
            state.isLoadingCancelsubInvoice = false;
            state.error = true;
        },//refundInvoice
        [refundInvoice.pending]: (state, action) => {
            state.isLoadingRefundInvoice = true;
            state.error = false;
        },
        [refundInvoice.fulfilled]: (state, action) => {
            state.invoiceDetails = action?.payload?.data;
            state.isLoadingRefundInvoice = false;
            state.error = false;
        },
        [refundInvoice.rejected]: (state, action) => {
            state.isLoadingRefundInvoice = false;
            state.error = true;
        },
        [sendEmailNotification.pending]:(state,action)=>{
            state.isLoadingSendEmailNotification=true;
            state.error=false;
        },
        [sendEmailNotification.fulfilled]:(state,action)=>{
            state.isLoadingSendEmailNotification=false;
            state.error=false;
        },
        [sendEmailNotification.rejected]:(state,action)=>{
            state.isLoadingSendEmailNotification=false;
            state.error=true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.invoice.error;
export const getIsLoadingAddInvoice = (state) =>
    state.invoice.isLoadingAddInvoice;
export const getReturnData = (state) =>
    state.invoice.postReturnData;
export const getIsLoadingInvoiceDetails = (state) =>
    state.invoice.isLoadingGetInvoiceDetails;
export const getIsLoadingEditInvoiceByid = (state) =>
    state.invoice.isLoadingEditInvoiceByid;
export const getIsLoadingInvoiceByGoal = (state) =>
    state.invoice.isLoadingGetInvoiceByGoal;
export const getInvoiceDetails = (state) =>
    state.invoice.invoiceDetails;
export const getInvoicesForGoal = (state) =>
    state.invoice.invoicesForGoal;

export const getIsLoadingPublishInvoiceById = (state) =>
    state.invoice.isLoadingPublishInvoiceById;
export const getIsLoadingPayInvoiceById = (state) =>
    state.invoice.isLoadingPayInvoiceById;
export const getIsLoadingCancelInvoiceById = (state) =>
    state.invoice.isLoadingCancelInvoiceById;
export const getIsLoadingDirectPayInvoice = (state) =>
    state.invoice.isLoadingDirectPayInvoice;
export const getIsLoadingDirectPaySubInvoice = (state) =>
    state.invoice.isLoadingDirectPaySubInvoice;
export const getIsLoadingInvoicesForInstitution = (state) =>
    state.invoice.isLoadingGetInvoicesForInstitution;
export const getInvoicesListForInstitution = (state) =>
    state.invoice.invoicesForInstitution;
export const getIsLoadingAddSubInvoices = (state) =>
    state.invoice.isLoadingAddSubInvoices;
export const getIsLoadingPublishSubInvoiceById = (state) =>
    state.invoice.isLoadingPublishSubInvoiceById;
export const getIsLoadingPaySubInvoice = (state) =>
    state.invoice.isLoadingPaySubInvoice;
export const getIsLoadingCancelsubInvoice = (state) =>
    state.invoice.isLoadingCancelsubInvoice;
export const getIsLoadingRefundInvoice = (state) =>
    state.invoice.isLoadingRefundInvoice;
export const getIsLoadingSendEmailNotifications=(state)=>
    state.invoice.isLoadingSendEmailNotification

export default invoiceSlice;
