import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: [],
};

const clientUISlice = createSlice({
  name: "clientUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeClientFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeClientColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.clientUI.tableFilters;
export const getColumnFilters = (state) => state.clientUI.columnFilters;

export const { storeClientFilters, storeClientColumnFilters } =
  clientUISlice.actions;

export default clientUISlice;
