import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: [],
};

const profileUISlice = createSlice({
  name: "profileUI",
  initialState,
  reducers: {
    storeProfileFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeProfileColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.profileUI.tableFilters;
export const getColumnFilters = (state) => state.profileUI.columnFilters;

export const { storeProfileFilters, storeProfileColumnFilters } = profileUISlice.actions;

export default profileUISlice;
