import { createSlice } from "@reduxjs/toolkit";
import { addVendorWorks, getAllVendorsWorkorderForInstitution, getVendorWorksforGoal, getVendorWorkorderById, editVendorWorkorderById, deleteVendorWorkOrder, publishVendorWorkOrder, getVendorWorksforVendor, getWorkorderSchema, editVendorWorkorderSchemaFields, addMultipleVendorWorks, getWorkorderTag } from "../actions/vendorWorks";
import { logoutUser } from "../actions/account";

const initialState = {
    error: false,
    isLoadingAddVendorWorks: false,
    isLoadingGetWorkordersByInstitution: false,
    vendorWorkorderList: [],
    vendors: [],
    isLoadingGetVendorWorksforGoal: false,
    vendorWorksForGoal: [],
    isLoadingGetVendorWorksforVendor: false,
    vendorWorksForVendor: [],
    isLoadingGetVendorWorkById: false,
    vendorWorkorderDetails: {},
    vendor: {},
    vendorSchema: [],
    vendorWorkorderSchema: [],
    venderWorkorderLogs: [],
    workorderReturnData: {},
    isLoadingEditVendorWorkorder: false,
    isLoadingDeleteVendorWorkOrder: false,
    isLoadingPublishVendorWorkOrder: false,
    isLoadingGetWorkorderSchema: false,
    isLoadingEditVendorWOSchemaFields: false,
    isLoadingAddMultipleWorkorders: false,
    workorderTags: {},
    isLoadingetWorkorderTag: false,
};

const vendorWorkSlice = createSlice({
    name: "vendorWorks",
    initialState,
    reducers: {},
    extraReducers: {
        //getAllVendorsWorkorderForInstitution
        [getAllVendorsWorkorderForInstitution.pending]: (state, action) => {
            state.isLoadingGetWorkordersByInstitution = true;
            state.error = false;
        },
        [getAllVendorsWorkorderForInstitution.fulfilled]: (state, action) => {
            state.isLoadingGetWorkordersByInstitution = false;
            state.vendorWorkorderList = action?.payload?.data?.vendorWorks;
            state.vendors = action?.payload?.data?.vendors;
            state.workorderTags = action?.payload?.data?.vendorworkorder_tags
            state.error = false;
        },
        [getAllVendorsWorkorderForInstitution.rejected]: (state, action) => {
            state.isLoadingGetWorkordersByInstitution = false;
            state.error = true;
        },//getVendorWorkorderById
        [getVendorWorkorderById.pending]: (state, action) => {
            state.isLoadingGetVendorWorkById = true;
            state.error = false;
        },
        [getVendorWorkorderById.fulfilled]: (state, action) => {
            state.isLoadingGetVendorWorkById = false;
            state.vendorWorkorderDetails = action?.payload?.data?.vendorWork;
            state.vendorWorkorderSchema = action?.payload?.data?.vendor_work_schema;
            state.venderWorkorderLogs = action?.payload?.data?.vendorworkorder_logs;
            state.vendor = action?.payload?.data?.vendor;
            state.vendorSchema = action?.payload?.data?.vendor_schema;
            state.error = false;
            state.workorderTags = action?.payload?.data?.vendorworkorder_tags
        },
        [getVendorWorkorderById.rejected]: (state, action) => {
            state.isLoadingGetVendorWorkById = false;
            state.error = true;
        },//getVendorWorksforGoal
        [getVendorWorksforGoal.pending]: (state, action) => {
            state.isLoadingGetVendorWorksforGoal = true;
            state.error = false;
        },
        [getVendorWorksforGoal.fulfilled]: (state, action) => {
            state.isLoadingGetVendorWorksforGoal = false;
            state.vendorWorksForGoal = action?.payload?.data?.vendorWorks;
            state.vendors = action?.payload?.data?.vendors;
            state.workorderTags = action?.payload?.data?.vendorworkorder_tags
            state.error = false;
        },
        [getVendorWorksforGoal.rejected]: (state, action) => {
            state.isLoadingGetVendorWorksforGoal = false;
            state.error = true;
        },//getVendorWorksforVendor
        [getVendorWorksforVendor.pending]: (state, action) => {
            state.isLoadingGetVendorWorksforVendor = true;
            state.error = false;
        },
        [getVendorWorksforVendor.fulfilled]: (state, action) => {
            state.isLoadingGetVendorWorksforVendor = false;
            state.vendorWorksForVendor = action?.payload?.data;
            state.error = false;
        },
        [getVendorWorksforVendor.rejected]: (state, action) => {
            state.isLoadingGetVendorWorksforVendor = false;
            state.error = true;
        },
        //addVendorWorks
        [addVendorWorks.pending]: (state, action) => {
            state.isLoadingAddVendorWorks = true;
            state.error = false;
        },
        [addVendorWorks.fulfilled]: (state, action) => {
            state.workorderReturnData = action?.payload?.data;
            state.isLoadingAddVendorWorks = false;
            state.error = false;
        },
        [addVendorWorks.rejected]: (state, action) => {
            state.isLoadingAddVendorWorks = false;
            state.error = true;
        },//addMultipleVendorWorks
        [addMultipleVendorWorks.pending]: (state, action) => {
            state.isLoadingAddMultipleWorkorders = true;
            state.error = false;
        },
        [addMultipleVendorWorks.fulfilled]: (state, action) => {
            state.isLoadingAddMultipleWorkorders = false;
            state.error = false;
        },
        [addMultipleVendorWorks.rejected]: (state, action) => {
            state.isLoadingAddMultipleWorkorders = false;
            state.error = true;
        },//editVendorWorkorderItemsById
        [editVendorWorkorderById.pending]: (state, action) => {
            state.isLoadingEditVendorWorkorder = true;
            state.error = false;
        },
        [editVendorWorkorderById.fulfilled]: (state, action) => {
            state.isLoadingEditVendorWorkorder = false;
            state.error = false;
        },
        [editVendorWorkorderById.rejected]: (state, action) => {
            state.isLoadingEditVendorWorkorder = false;
            state.error = true;
        },//editVendorWorkorderSchemaFields
        [editVendorWorkorderSchemaFields.pending]: (state, action) => {
            state.isLoadingEditVendorWOSchemaFields = true;
            state.error = false;
        },
        [editVendorWorkorderSchemaFields.fulfilled]: (state, action) => {
            state.isLoadingEditVendorWOSchemaFields = false;
            state.error = false;
        },
        [editVendorWorkorderSchemaFields.rejected]: (state, action) => {
            state.isLoadingEditVendorWOSchemaFields = false;
            state.error = true;
        },//
        [deleteVendorWorkOrder.pending]: (state, action) => {
            state.isLoadingDeleteVendorWorkOrder = true;
            state.error = false;
        },
        [deleteVendorWorkOrder.fulfilled]: (state, action) => {
            state.isLoadingDeleteVendorWorkOrder = false;
            state.error = false;
        },
        [deleteVendorWorkOrder.rejected]: (state, action) => {
            state.isLoadingDeleteVendorWorkOrder = false;
            state.error = true;
        },
        [publishVendorWorkOrder.pending]: (state, action) => {
            state.isLoadingPublishVendorWorkOrder = true;
            state.error = false;
        },
        [publishVendorWorkOrder.fulfilled]: (state, action) => {
            state.isLoadingPublishVendorWorkOrder = false;
            state.vendorWorkorderDetails = action?.payload?.data?.vendorWork;
            state.vendorWorkorderSchema = action?.payload?.data?.vendor_work_schema;
            state.error = false;
        },
        [publishVendorWorkOrder.rejected]: (state, action) => {
            state.isLoadingPublishVendorWorkOrder = false;
            state.error = true;
        },//getWorkorderSchema
        [getWorkorderSchema.pending]: (state, action) => {
            state.isLoadingGetWorkorderSchema = true;
            state.error = false;
        },
        [getWorkorderSchema.fulfilled]: (state, action) => {
            state.isLoadingGetWorkorderSchema = false;
            state.vendorWorkorderSchema = action?.payload?.data
            state.error = false;
        },
        [getWorkorderSchema.rejected]: (state, action) => {
            state.isLoadingGetWorkorderSchema = false;
            state.error = true;
        },//getWorkorderTag
        [getWorkorderTag.pending]: (state, action) => {
            state.isLoadingetWorkorderTag = true;
            state.error = false;
        },
        [getWorkorderTag.fulfilled]: (state, action) => {
            state.isLoadingetWorkorderTag = false;
            state.workorderTags = action?.payload?.data;
            state.error = false;
        },
        [getWorkorderTag.rejected]: (state, action) => {
            state.isLoadingetWorkorderTag = false;
            state.error = true;
        },
        [logoutUser.fulfilled]: (state) => {
            return initialState;
        },
        [logoutUser.rejected]: (state) => {
            return initialState;
        },
    },
});

export const getError = (state) => state.vendorWorks.error;
export const getIsLoadingAddVendorWorks = (state) => state.vendorWorks.isLoadingAddVendorWorks;
export const getIsLoadingWorkordersByInstitution = (state) => state.vendorWorks.isLoadingGetWorkordersByInstitution;
export const getVendorWorkorderList = (state) => state.vendorWorks.vendorWorkorderList;
export const getVendorsForWorkorder = (state) => state.vendorWorks.vendors;
export const getVendorSchemaForWorkorder = (state) => state.vendorWorks.vendorSchema;
export const getVendorWorksForGoal = (state) => state.vendorWorks.vendorWorksForGoal;
export const getIsLoadingVendorWorksforGoal = (state) => state.vendorWorks.isLoadingGetVendorWorksforGoal;
export const getIsLoadingGetVendorWorksforVendor = (state) => state.vendorWorks.isLoadingGetVendorWorksforVendor;
export const getVendorWorksForVendor = (state) => state.vendorWorks.vendorWorksForVendor;
export const getIsLoadingVendorWorkById = (state) => state.vendorWorks.isLoadingGetVendorWorkById;
export const getVendorWorkorderDetails = (state) => state.vendorWorks.vendorWorkorderDetails;
export const getVendorWorkorderSchema = (state) => state.vendorWorks.vendorWorkorderSchema;
export const getWorkorderReturnData = (state) => state.vendorWorks.workorderReturnData;
export const getIsLoadingEditVendorWorkorder = (state) => state.vendorWorks.isLoadingEditVendorWorkorder;
export const getIsLoadingDeleteVendorWorkOrder = (state) => state.vendorWorks.isLoadingDeleteVendorWorkOrder;
export const getIsLoadingPublishVendorWorkOrder = (state) => state.vendorWorks.isLoadingPublishVendorWorkOrder;
export const getVendorForVendorWorkorder = (state) => state.vendorWorks.vendor;
export const getIsLoadingWorkorderSchema = (state) => state.vendorWorks.isLoadingGetWorkorderSchema;
export const getIsLoadingEditVendorWOSchemaFields = (state) => state.vendorWorks.isLoadingEditVendorWOSchemaFields;
export const getVenderWorkorderLogs = (state) => state.vendorWorks.venderWorkorderLogs;
export const getIsLoadingAddMultipleWorkorders = (state) => state.vendorWorks.isLoadingAddMultipleWorkorders;
export const getWorkorderTags = (state) => state.vendorWorks.workorderTags;
export const getIsLoadingetWorkorderTag = (state) => state.vendorWorks.isLoadingetWorkorderTag;




export default vendorWorkSlice;


