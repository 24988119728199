import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, composeAuth, AuthError } from "../../helpers/utils";
import { logoutUser } from './account'

export const getHouseAllocation = createAsyncThunk(
    "nycha/getHouseAllocation",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload.jwt);
            const url = process.env.REACT_APP_SERVER + "/api/nycha";
            const res = await fetch(url, {
                method: "POST",
                body: JSON.stringify(payload.requestBody),
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser())
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getAllSimulations = createAsyncThunk(
    "nycha/getAllSimulations",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/nycha/institution/${payload?.institutionId}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const getSimulationById = createAsyncThunk(
    "nycha/getSimulationById",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/nycha/institution/${payload?.institutionId}/id/${payload?.simulationId}`
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const assignTransfers = createAsyncThunk(
    "nycha/assignTransfers",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/housing/assign`
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    "Content-type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload.requestBody),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
