import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: {},
};

const goalsUISlice = createSlice({
  name: "goalsUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeGoalFilters(state, action) {
      state.tableFilters = action.payload;
    },
    clearGoalFilters(state, action) {
      state.tableFilters = {};
    },
    storeGoalColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.goalsUI.tableFilters;
export const getColumnFilters = (state) => state.goalsUI.columnFilters;

export const {
  storeGoalFilters,
  clearGoalFilters,
  storeGoalColumnFilters,
} = goalsUISlice.actions;

export default goalsUISlice;
