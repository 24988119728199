import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableFilters: {},
  columnFilters: [],
};

const locationUISlice = createSlice({
  name: "locationUI",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeLocationFilters(state, action) {
      state.tableFilters = action.payload;
    },
    storeLocationColumnFilters(state, action) {
      state.columnFilters = action.payload;
    },
  },
  extraReducers: {},
});

export const getTableFilters = (state) => state.locationUI.tableFilters;
export const getColumnFilters = (state) => state.locationUI.columnFilters;

export const { storeLocationFilters, storeLocationColumnFilters } = locationUISlice.actions;

export default locationUISlice;
